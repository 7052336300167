export default class ProjectTranslation {
    id = null;
    title = null;
    description = null;
    language_id = null;
    projectId = null;
    language = {};

    constructor() {
        this.language = {id: null}
    }
}
