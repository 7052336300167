<template>
  <v-row justify="center">
    <v-dialog
      :value="visible"
      max-width="700px"
      persistent
    >
      <v-card dark>
        <v-card-title>
          Translations
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="translations"
            :loading="loading_translations"
            no-data-text="no translations available"
          >
            <template #item.language_id="{item}">
              {{ getLanguage(item.language_id) }}
            </template>

            <template #item.actions="{item}">
              <v-btn
                icon
                @click="openTranslationDetails(item)"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            small
            color="primary"
            @click="createTranslation()"
          >
            Add Translation
          </v-btn>
          <v-btn
            depressed
            small
            color="primary"
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
      <ProjectTranslationDetail
        :visible="dialog_translation_detail"
        :data="selected_translation"
        :is-new="isNew"
        :value="projectTranslationsDetail"
        @reload="loadProjectTranslations"
        @close="dialog_translation_detail = false"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment'
import {mapState, mapActions, mapMutations} from 'vuex'
import _ from 'lodash'
import ProjectTranslation from '@/types/ProjectTranslation'
import ProjectTranslationDetail from './ProjectTranslationDetail'
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin'
import projectService from '@/services/Project.service'

export default {
  name: 'ProjectTranslation',
  components: {ProjectTranslationDetail},
  mixins: [ValidationRulesMixin],
  props: {
    visible: Boolean,
    projectId: {
      type: Number,
      default: Number
    }
  },
  data() {
    return {
      dialog_translation_detail: false,
      loading_translations: false,
      isNew: false,
      translations: [],
      selected_translation: {},
      headers: [
        {
          text: 'title',
          value: 'title'
        },
        {
          text: 'description',
          value: 'description'
        },
        {
          text: 'language',
          value: 'language_id'
        },
        {
          text: '',
          value: 'actions',
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapState('project', ['dialog', 'projectData', 'projectTranslations', 'projectTranslationsDetail']),
    ...mapState('globals', ['languages'])

  },
  watch: {
    visible(val) {
      if (val) {
        this.loadProjectTranslations()
        this.loadLanguages()
      }
    }
  },
  methods: {
    ...mapActions('project', ['createUpdateProjectTranslations']),
    ...mapMutations('project', ['setDialogProjectTranslation', 'setTranslationDetail', 'setDialogsTranslationDetail']),
    ...mapActions('globals', ['loadLanguages']),

    async loadProjectTranslations() {
      this.loading_translations = true
      this.translations = await projectService.loadTranslations(this.projectId)
      this.loading_translations = false
    },

    closeDialog() {
      this.$emit('close')
    },

    createTranslation() {
      this.selected_translation = new ProjectTranslation()
      this.selected_translation.projectId = this.projectId
      this.isNew = true
      this.dialog_translation_detail = true
    },

    openTranslationDetails(item) {
      this.selected_translation = _.clone(item)
      this.isNew = false
      this.dialog_translation_detail = true
    },

    getLanguage(lang) {
      console.log(lang, this.languages, this.languages.find((l) => l.id === lang))
      return this.languages.find((l) => l.id === lang)?.description
    },

    openDetail(item) {
      console.log('detail', item)
      this.setTranslationDetail(item)
      this.setDialogsTranslationDetail(true)
    }
  },


}
</script>

<style scoped>

</style>
