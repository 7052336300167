<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="900px"
  >
    <v-card>
      <v-card-title>
        Timetable
      </v-card-title>
      <v-card-text>
        <v-sheet
          tile
          height="54"
          class="d-flex"
        >
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            outlined
            @click="setToday"
          >
            Today
          </v-btn>
          <v-spacer />
          <span v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </span>
          <span
            v-if="type === 'week'"
            class="ml-4"
          >
                        KW{{ getKW() }}
                    </span>
          <v-spacer />
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            locale="de"
            :weekdays="weekday"
            :events="data"
            :event-color="getEventColor"
            :type="type"
            event-overlap-mode="column"
            :event-overlap-threshold="45"
            @click:event="showEvent"
            @click:date="addEvent"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            dark
          >

            <NewEventDialog
              :event="selectedEvent"
              :is-new="false"
              @close="closeSelecetedEvent"
              @save="saveSelectedEvent"
              @deleteEvent="deleteEvent"
            />
          </v-menu>
        </v-sheet>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          small
          color="primary"
          @click="close"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="newEventDialog"
      persistent
      max-width="500px"
    >
      <NewEventDialog
        :event="newEvent"
        :is-new="true"
        @close="closeNewEvent"
        @save="saveNewEvent"
      />
    </v-dialog>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import NewEvent from '../../types/NewEvent'
import NewEventDialog from './NewEventDialog'
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin'
import projectTimetableService from '@/services/ProjectTimetable.service'
import projectService from '@/services/Project.service'
import Vue from 'vue'

export default {
  name: 'ProjectTimetableDialog',
  components: {NewEventDialog},
  mixins: [ValidationRulesMixin],
  props: {
    visible: Boolean,
    projectId: {
      type: Number,
      default: Number
    }
  },
  data() {
    return {
      weekday: [1, 2, 3, 4, 5, 6, 0],
      type: 'month',
      focus: '',
      data: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      selectedEdit: false,
      writebackEvent: {},
      newEvent: {},
      newEventDialog: false,
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      typeToLabel: {
        month: 'Month',
        week: 'Week',
      },
    }
  },
  computed: {
  },
  watch: {
    visible(v) {
      if (v) {
        this.loadTimetable()
        this.focus = moment().format('YYYY-MM-DD')
      }
    },
  },
  mounted() {
  },
  methods: {
    async loadTimetable() {
      this.data = await projectTimetableService.loadEvents(this.projectId)
    },

    dt(iso) {
      const d = moment(iso)
      return (d.isValid() ? d.format('YYYY-MM-DD HH:mm') : '')
    },

    close() {
      this.$emit('reload')
      this.$emit('close')
    },
    getEventColor(event) {
      return event.color
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.writebackEvent = event
        this.selectedEvent = _.clone(event)
        this.selectedEvent.startdate = this.selectedEvent.start.split(' ')[0]
        this.selectedEvent.starttime = this.selectedEvent.start.split(' ')[1]
        this.selectedEvent.enddate = this.selectedEvent.end.split(' ')[0]
        this.selectedEvent.endtime = this.selectedEvent.end.split(' ')[1]
        this.selectedElement = nativeEvent.target
        this.selectedOpen = true
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    addEvent({date}) {
      console.log('date', date)
      this.newEvent = new NewEvent(date)
      this.newEventDialog = true
    },
    parseStartTime(event) {
      this.selectedEvent.starttime = moment(event, 'HHmm').format('HH:mm')
    },
    parseEndTime(event) {
      console.log(event)
    },

    newEventStarttime(time) {
      const m = moment(this.newEvent.startdate + ' ' + time, 'YYYY-MM-DD HH:mm')
      m.add(1, 'Hour')
      this.newEvent.enddate = m.format('YYYY-MM-DD')
      this.newEvent.endtime = m.format('HH:mm')
    },
    closeNewEvent() {
      this.newEventDialog = false
    },
    async saveNewEvent(event) {
      console.log('save', event)
      event.start = event.startdate + ' ' + event.starttime
      event.end = event.enddate + ' ' + event.endtime
      event.projectId = this.projectId
      try {
        await projectTimetableService.createEvent(this.projectId, event)
        Vue.prototype.$toast('Event created', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })

        await this.loadTimetable()
      } catch (e) {
        Vue.prototype.$toast('Event creation failed', {
          type: 'error',
          top: true,
          right: true,
          timeout: 3000,
        })
      }
      this.newEventDialog = false
      this.focus = this.newEvent.startdate
    },

    closeSelecetedEvent() {
      this.selectedOpen = false
    },
    async saveSelectedEvent(event) {
      try {
        await projectTimetableService.saveEvent(this.projectId, event)
        Vue.prototype.$toast('Event updated', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
        await this.loadTimetable()
        this.selectedOpen = false
      } catch (e) {
        Vue.prototype.$toast('Event update failed', {
          type: 'error',
          top: true,
          right: true,
          timeout: 3000,
        })
      }
    },
    async deleteEvent(event) {
      console.log('Del', event)
      try {
        await projectTimetableService.deleteEvent(this.projectId, event.id)
        Vue.prototype.$toast('Event deleted', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
        await this.loadTimetable()
        this.selectedOpen = false
      } catch (e) {
        Vue.prototype.$toast('delete failed', {
          type: 'error',
          top: true,
          right: true,
          timeout: 3000,
        })
      }
    },
    getKW() {
      if (this.focus) {
        return moment(this.focus, 'YYYY-MM-DD').week()
      }
      return moment().week()
    }
  },
}
</script>

<style scoped>
</style>
