<template>
  <v-data-table
    :items="items"
    :headers="headers"
  >
    <template #item.actions="{item}">
      <v-btn
        icon
        color="green"
        @click="updateProjectVisibility(item.id, false)"
      >
        <v-icon>
          mdi-eye
        </v-icon>
      </v-btn>
      <v-btn
        depressed
        small
        class="ml-1"
        @click="$router.push({name: 'PerformanceManager', params: {id: item.id}})"
      >
        Live
      </v-btn>
      <v-btn
        depressed
        small
        class="ml-1"
        @click="confirmReset(item.id)"
      >
        Reset
      </v-btn>
    </template>

    <template #item.starttime="{item}">
      {{ getNextDate(item) }}
    </template>
  </v-data-table>
</template>

<script>
import {mapActions} from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'PublishedDataTable',
  props: {
    items: {
      default: Array,
      type: Array,
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'project',
          value: 'name'
        },
        {
          text: 'start time',
          value: 'starttime'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right'
        }
      ]
    }
  },
  methods: {
    ...mapActions('project', ['updateVisibility']),
    updateProjectVisibility(id, value) {
      this.$emit('updateVisibility', id, value)
    },
    getNextDate(item) {
      if (item.projectTimetables.length === 0) {
        return 'No playdates available'
      }

      const pd = _.sortBy(item.projectTimetables.filter(e => moment(e.start).isSameOrAfter(moment())), 'start')
      if (pd.length) {
        return this.$dt(pd[0].start)
      }
      return 'No playdates available'
    },
    async confirmReset(id) {
      this.$confirm('reset project to beginning', {
        type: 'warning'
      })
      .then((val) => {
        if (val) {
          axios.put(`/api/performance/projects/${id}/reset`)
          .then(() => {
           this.$toast('project resettet', {
             type: 'success',
             top: true,
             right: true,
             timeout: 3000,
           });
          })
        }
      })

    }
  }

}
</script>

<style scoped>

</style>
