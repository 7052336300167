<template>
  <v-dialog
    :value="visible"
    max-width="400px"
    persistent
  >
    <v-card>
      <v-card-title>
        Add User
      </v-card-title>
      <v-card-text>
        <v-form ref="newUser">
          <v-select
            v-model="user"
            :items="users"
            item-value="id"
            item-text="username"
            :loading="loading_users"
            :rules="[rules.required()]"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          small
          color="primary"
          @click="saveUser"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          @click="close()"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import _ from 'lodash'
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin'
import hostService from '@/services/Host.service'
import projectService from '@/services/Project.service'

export default {
  name: 'ProjectUserDetail',
  mixins: [ValidationRulesMixin],
  props: {
    visible: Boolean,
    value: {
      default: Object,
      type: Object
    },
    projectId: {
      type: Number,
      default: Number
    }
  },
  data() {
    return {
      user: null,
      users: [],
      loading_users: false,
    }
  },
  computed: {
  },
  watch: {
    visible(val) {
      if (val) {
        this.user = null
        this.loadUsersToHost()
      }
    }
  },
  methods: {
    async loadUsersToHost() {
      this.loading_users = true
      this.users = await hostService.loadUserToHost()
      this.loading_users = false
    },

    close() {
      this.$emit('close')
    },

    async saveUser() {

      if (this.$refs.newUser.validate()) {
        try {
          await projectService.addUserToProject(this.projectId, this.user)
          this.$toast('user added', {
            type: 'success',
            top: true,
            right: true,
            timeout: 3000,
          })
          this.$emit('reload');
          this.$emit('close');
        } catch (e) {
          this.$toast('user adding failed', {
            type: 'error',
            top: true,
            right: true,
            timeout: 3000,
          })
        }
      }
    },

  }
}
</script>

<style scoped>

</style>
