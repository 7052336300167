<template>
  <v-row justify="center">
    <v-dialog
      :value="visible"
      max-width="500px"
      persistent
    >
      <v-card dark>
        <v-card-title>
          Translations of project title to phone language
          <v-card-subtitle>
            as default the project title is used
          </v-card-subtitle>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="translation"
            lazy-validation
          >
            <v-text-field
              v-model="translation.title"
              label="title"
              :rules="[rules.required()]"
            ></v-text-field>
            <v-text-field
              v-model="translation.description"
              label="description"
              :rules="[rules.required()]"
            ></v-text-field>
            <v-select
              v-model="translation.language_id"
              label="language"
              :items="languages"
              item-text="description"
              item-value="id"
              :rules="[rules.required()]"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            small
            color="primary"
            @click="saveTranslation"
          >
            Save
          </v-btn>
          <v-btn
            v-if="!isNew"
            depressed
            small
            color="primary"
            @click="deleteTranslation"
          >
            delete
          </v-btn>
          <v-btn
            depressed
            small
            color="primary"
            @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </v-row>
</template>

<script>
import moment from 'moment'
import {mapState, mapActions, mapMutations} from 'vuex'
import _ from 'lodash'
import ProjectTranslation from '@/types/ProjectTranslation'
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin'
import projectService from '@/services/Project.service'

export default {
  name: 'ProjectTranslationDetail',
  mixins: [ValidationRulesMixin],
  props: {
    visible: Boolean,
    data: {
      default: new ProjectTranslation(),
      type: Object
    },
    isNew: Boolean,

  },
  data() {
    return {
      translation: {}
    }
  },
  computed: {
    ...mapState('project', ['projectTranslationDetail']),
    ...mapState('globals', ['languages'])
  },
  watch: {
    visible(val) {
      if (val) {
        this.translation = this.data
        this.$nextTick(() => {
          this.$refs.translation.resetValidation()
        })
      }
    }
  },
  mounted() {
    this.translation = new ProjectTranslation()
  },
  methods: {
    close() {
      this.$emit('close')
    },

    async saveTranslation() {
      if (this.$refs.translation.validate()) {
        if (this.isNew) {
          try {
            await projectService.createTranslation(this.translation.projectId, this.translation)
            this.$toast('translation created', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            });
            this.$emit('reload')
            this.close()
          } catch (e) {
            this.$toast('create failed', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            });
          }
        } else {
          try {
            await projectService.updateTranslation(this.translation.projectId, this.translation.id, this.translation)
            this.$toast('translation updated', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            });
            this.$emit('reload')
            this.close()
          } catch (e) {
            this.$toast('update failed', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            });
          }
        }
      }
    },

    async deleteTranslation() {
      try {
        await projectService.deleteTranslation(this.translation.projectId, this.translation.id)
        this.$toast('translation deleted', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        });
        this.$emit('reload')
        this.close()
      } catch (e) {
        this.$toast('delete failed', {
          type: 'error',
          top: true,
          right: true,
          timeout: 3000,
        });
      }
    },

  }


}
</script>

<style scoped>

</style>
