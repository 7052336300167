<template>
  <v-card
    color="card"
  >
    <v-card-title>
      Project Manager
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-card>
            <v-card-title>
              Published
            </v-card-title>
            <v-card-text>
              <PublishedDataTable
                :items="projects_active"
                @updateVisibility="updateVisibility"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card>
            <v-card-title>
              Inactive
            </v-card-title>
            <v-card-text>
              <InactiveDataTable
                :items="projects_inactive"
                @openDetails="openDetails"
                @openUsers="openUsers"
                @openTimetable="openTimetable"
                @openTranslations="openTranslations"
                @updateVisibility="updateVisibility"
                @deleteProject="confirmDeleteProject"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        small
        color="primary"
        @click="newProject"
      >
        Create Project
      </v-btn>
    </v-card-actions>
    <ProjectDetail
      :visible="dialog_project_detail"
      :is-new="isNew"
      :project-id="selected_project_id"
      @close="dialog_project_detail = false"
      @reload="loadProjects"
    />
    <ProjectUser
      :visible="dialog_projectuser"
      :project-id="selected_project_id"
      @close="dialog_projectuser = false"
    />
    <ProjectTranslation
      :visible="dialog_project_translation"
      :project-id="selected_project_id"
      @close="dialog_project_translation = false"
    />

    <ProjectTimetableDialog
      :visible="dialog_timetable"
      :project-id="selected_project_id"
      @close="dialog_timetable = false"
      @reload="loadProjects"
    />
  </v-card>
</template>

<script>
import moment from 'moment'
import ProjectDetail from '@/components/ProjectManager/ProjectDetail'
import {mapState, mapMutations, mapActions} from 'vuex'
import Project from '@/types/Project'
import ProjectTranslation from '@/components/ProjectManager/ProjectTranslation'
import PublishedDataTable from '../components/ProjectManager/PublishedDataTable'
import InactiveDataTable from '../components/ProjectManager/InactiveDataTable'
import projectService from '@/services/Project.service'
import ProjectTimetableDialog from '@/components/ProjectManager/ProjectTimetableDialog'
import ProjectUser from '@/components/ProjectManager/ProjectUser'

export default {
  name: 'ProjectManager',
  components: {
    ProjectDetail,
    ProjectUser,
    ProjectTranslation,
    ProjectTimetableDialog,
    InactiveDataTable,
    PublishedDataTable,
  },
  data() {
    return {
      dialog_project_detail: false,
      dialog_project_translation: false,
      dialog_projectuser: false,
      dialog_timetable: false,
      dialog_project: false,

      selected_project_id: null,
      selected_project: null,

      loading_projects: false,
      projects: [],
      isNew: false,

    }
  },
  computed: {
    ...mapState('project', ['dialog']),
    ...mapState('admin', ['host_id']),

    projects_active() {
      return this.projects.filter(e => e.active)
    },
    projects_inactive() {
      return this.projects.filter(e => !e.active)
    }

  },

  mounted() {
    this.loadProjects()
  },

  methods: {
    async loadProjects() {
      this.loading_projects = true
      this.projects = await projectService.loadProjects()
      this.loading_projects = false
    },

    newProject() {
      this.selected_project_id = null
      this.isNew = true
      this.dialog_project_detail = true
    },
    openDetails(item) {
      this.selected_project_id = item.id
      this.isNew = false
      this.dialog_project_detail = true
    },
    openUsers(item) {
      console.log('item', item)
      this.selected_project_id = item.id
      this.dialog_projectuser = true
    },
    openTimetable(item) {
      this.selected_project_id = item.id
      this.dialog_timetable = true
    },
    openTranslations(item) {
      this.selected_project_id = item.id
      this.dialog_project_translation = true
    },
    async confirmDeleteProject(item) {
      this.$confirm('delete project?', {
        type: 'delete'
      })
        .then(async value => {
          if (value) {
            await projectService.deleteProject(item.id)
            await this.loadProjects()
          }
        })
    },

    async updateVisibility(id, value) {
      await projectService.updateVisibility(id, value)
      await this.loadProjects()
    }
  },

}
</script>

<style scoped>

</style>
