<template>
  <v-data-table
    :items="ordereditems"
    :headers="headers"
  >
    <template #item.actions="{item}">
      <v-btn
        icon
        color="red"
        @click="updateProjectVisibility(item.id, true)"
      >
        <v-icon>
          mdi-eye
        </v-icon>
      </v-btn>

      <v-btn
        small
        depressed
        @click="$router.push({name: 'ContentManager', params: {id: item.id}})"
      >
        <v-icon left>
          mdi-pencil
        </v-icon>
        Content
      </v-btn>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            depressed
            class="mr-1 ml-1"
            v-on="on"
          >
            performance
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="editProject(item)">
            <v-list-item-title>
              <v-icon left>
                mdi-pencil
              </v-icon>
              edit
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="editProjectUsers(item)">
            <v-list-item-title>
              <v-icon left>
                mdi-account
              </v-icon>
              users
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="editTranslation(item)">
            <v-list-item-title>
              <v-icon left>
                mdi-translate
              </v-icon>
              translations
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="editTimetable(item)">
            <v-list-item-title>
              <v-icon left>
                mdi-timetable
              </v-icon>
              timetable
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="deleteProject(item)">
            <v-list-item-title>
              <v-icon left>
                mdi-delete
              </v-icon>
              delete
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template #item.starttime="{item}">
      {{ getNextDate(item) }}
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'InactiveDataTable',
  props: {
    items: {
      default: Array,
      type: Array,
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'project',
          value: 'name'
        },
        {
          text: 'next playdate',
          value: 'starttime',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ordereditems: {
      get() {
        return _.sortBy(this.items, [function (o) {
          const pd = _.sortBy(o.projectTimetables.filter(e => moment(e.start).isSameOrAfter(moment())), 'start')
          if (pd.length) {
            console.log(pd[0].start)
            return moment(pd[0].start).format('YYYY-MM-DD')
          }
          return moment().add(1000, 'Years').format('YYYY-MM-DD')
        }, 'name'])
      }
    }
  },
  mounted() {
  },
  methods: {
    ...mapMutations('project', ['setDialogProjectDetail', 'setProjectData', 'setProjectTranslationData', 'setDialogProjectTranslation', 'setProjectUsers', 'setDialogsProjectUser', 'setDialogTimetable']),

    updateProjectVisibility(id, value) {
      this.$emit('updateVisibility', id, value)
    },
    editProject(pr) {
      this.$emit('openDetails', pr)
    },
    editTranslation(pr) {
      this.$emit('openTranslations', pr)
    },
    editProjectUsers(pr) {
      this.$emit('openUsers', pr)
    },
    editTimetable(pr) {
      this.$emit('openTimetable', pr)
    },
    deleteProject(pr) {
      this.$emit('deleteProject', pr)
    },
    getNextDate(item) {
      if (item.projectTimetables.length === 0) {
        return 'No playdates available'
      }

      const pd = _.sortBy(item.projectTimetables.filter(e => moment(e.start).isSameOrAfter(moment())), 'start')
      if (pd.length) {
        return this.$dt(pd[0].start)
      }
      return 'No playdates available'
    }
  }

}
</script>

<style scoped>

</style>
