<template>
  <v-dialog
    :value="visible"
    max-width="800px"
    persistent
  >
    <v-card dark>
      <v-card-title>
        Project
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="project">
              <v-text-field
                v-model="project.name"
                label="Name"
                :rules="[rules.required()]"
              />

              <v-text-field
                v-model="project.img_url"
                label="Titleimage URL"
                placeholder="https://myimge.com/img01.png"
                counter="200"
                :rules="[rules.maxLength(200)]"
              />

              <v-img
                v-if="project.img_url"
                :src="project.img_url"
              ></v-img>

              <v-checkbox
                v-model="project.active"
                label="Active"
                :value="false"
              />
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          small
          color="primary"
          @click="save"
        >
          save
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import moment from 'moment'
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
import _ from 'lodash'
import projectService from '@/services/Project.service'
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin'
import Project from '@/types/Project'

export default {
  name: 'ProjectDetail',
  mixins: [ValidationRulesMixin],
  props: {
    visible: Boolean,
    isNew: Boolean,
    projectId: {
      type: Number,
      default: Number
    }
  },
  data() {
    return {
      project: {},
      loading_details: false,
      btnText: '',
      headers: [
        {
          text: 'username',
          value: 'username'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('authentication', ['getHostId'])
  },
  watch: {
    visible(val) {
      if (val) {
        console.log(this.isNew, this.projectId)
        if (this.isNew) {
          this.createProject()
        } else {
          this.loadProjectDetails()
        }
      }
    }
  },
  mounted() {
  },
  methods: {

    async loadProjectDetails() {
      this.loading_details = true
      this.project = await projectService.loadProjectDetails(this.projectId)
      this.loading_details = false
    },

    createProject() {
      this.project = new Project(this.getHostId)
    },


    ...mapActions('project', ['updateCreateProject']),
    ...mapMutations('project', ['setDialogProjectDetail']),

    async save() {
      console.log('save', this.project)
      if (this.$refs.project.validate()) {
        if (this.isNew) {
          try {
            await projectService.createProject(this.project)
            this.$toast('project created', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            this.$emit('reload')
            this.close()
          } catch (e) {
            this.$toast('create failed', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }
        } else {
          try {
            await projectService.updateProject(this.projectId, this.project)
            this.$toast('project updated', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            this.$emit('reload')
            this.close()
          } catch (e) {
            this.$toast('update failed - ' + e.data.message, {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }
        }
      }
    },
    close() {
      this.$emit('close')
    }
  },


}
</script>

<style scoped>

</style>
