<template>
  <v-dialog
    :value="visible"
    max-width="500px"
    persistent
  >
    <v-card dark>
      <v-card-title>
        Project Users
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="users"
          :loading="loading_users"
        >
          <template #item.actions="{item}">
            <v-btn
              icon
              @click="deleteUser(item)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          small
          color="primary"
          @click="openAddUser"
        >
          Add User
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <ProjectUserDetail
      :visible="dialog_userdetail"
      :project-id="projectId"
      @close="dialog_userdetail = false"
      @reload="loadUsersToProject"
    />
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import ProjectUserDetail from './ProjectUserDetail'
import hostService from '@/services/Host.service'
import projectService from '@/services/Project.service'

export default {
  name: 'ProjectUser',
  components: {
    ProjectUserDetail
  },
  props: {
    visible: Boolean,
    projectId: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },
  data() {
    return {
      users: [],
      dialog_projectuser: false,
      dialog_userdetail: false,
      loading_users: false,
      headers: [
        {
          text: 'username',
          value: 'username'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right'
        }
      ],
      addUser: {
        dialog: false,
        user: {}
      },

    }
  },
  computed: {},
  watch: {
    visible(val) {
      if (val) {
        this.loadUsersToProject()
      }
    }
  },
  methods: {
    async loadUsersToProject() {
      this.loading_users = true
      const data = await projectService.loadUsersToProject(this.projectId)
      this.users = data
      this.loading_users = false
    },

    close() {
      this.$emit('close')
    },

    openAddUser() {
      this.dialog_userdetail = true
    },
    deleteUser(user) {
      this.$confirm('are your sure to delete this user?', {
        type: 'delete'

      }).then(async res => {
        if (res) {
          try {
            await projectService.deleteUserFromProject(this.projectId, user.id)
            this.$toast('user delete from project', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            await this.loadUsersToProject()
          } catch (e) {
            this.$toast('deletion error', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
