import axios from 'axios'
import store from '@/store'

class ProjectTimetableServiceClass {

  async loadEvents(project_id) {
    try {
      const {data} = await axios.get(`/api/project/${project_id}/timetable`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async saveEvent(project_id, payload) {
    console.log('try update event', payload)
    try {
      const {data} = await axios.post(`/api/project/${project_id}/updateEvent`, payload);
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async createEvent(project_id, payload) {
    try {
      const {data} = await axios.post(`/api/project/${project_id}/createEvent`, payload);
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async deleteEvent(project_id, event_id) {
    try {
      const {data} = await axios.delete(`/api/project/${project_id}/deleteEvent/${event_id}`);
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }



}

const projectTimetableService = new ProjectTimetableServiceClass()
export default projectTimetableService
